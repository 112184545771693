.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 2vmin);
  transition: background-color 0.5s ease;
}

.button {
  padding: 10px 20px; /* Padding */
  border: none; /* Remove border */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer;
  transition: background-color 0.5s ease; /* Smooth hover transition */
}

.profile-container {
  width: 150px; /* Adjust the width and height as needed */
  height: 150px;
  border: 3px solid; 
  border-radius: 50%; /* Makes it circular */
  overflow: hidden; /* Hides overflow if the image is larger than the container */
}

.profile-picture {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the circular container */
}

.default {
  background: linear-gradient(0.52turn, #0E21A0, #EC53B0);
  color: #ebbfd9;
  transition: background-color 0.5s ease;
}

.theme1 {
  background: linear-gradient(0.52turn, #274690, #1B264F);
  color: #F5F3F5;
  transition: background-color 0.5s ease;
}

.theme2 {
  background: linear-gradient(0.52turn, #D1E3DD, #6E7DAB);
  color: #32292F;
  transition: background-color 0.5s ease;
}

.theme3 {
  background: linear-gradient(0.52turn, #FFF2D8, #EAD7BB);
  color: #113946;
  transition: background-color 0.5s ease;
}

.theme4 {
  background: linear-gradient(0.52turn, #CCC8AA, #191717);
  color: #F1EFEF;
  transition: background-color 0.5s ease;
}